// Here you can easily change font faces which are used in your site.
// To give it a try, uncomment some of the lines below rebuild your blog, and see how it works. your sites's.
// If you love to use Web Fonts, you also need to add some lines to source/_includes/custom/head.html

//$sans: "Optima", sans-serif;
//$serif: "Baskerville", serif;
//$mono: "Courier", monospace;
//$heading-font-family: "Verdana", sans-serif;
//$header-title-font-family: "Futura", sans-serif;
//$header-subtitle-font-family: "Futura", sans-serif;
