#archive {
  #content > div { &, > article { padding-top: 0; } }
}
#blog-archives, #blog-category {
  article {
    padding: 1em 0 1em;
    position: relative;
    background: $img-border bottom left repeat-x;
    &:last-child {
      background: none;
    }
    footer { padding: 0; margin: 0;}
  }
  h1 { color: $text-color; margin-bottom: .3em; }
  h2 { display: none; }
  h1 {
    font-size: 1.5em;
    a {
      @include hover-link;
      color: inherit;
      &:hover { color: $link-color-hover; }
      font-weight: normal;
      display: inline-block;
    }
  }
  a.category, time {
    @extend .sans;
    color: $text-color-light;
  }
  color: $text-color-light;
  .entry-content { display: none; }
  time {
    font-size: .9em;
    line-height: 1.2em;
    .month, .day { display: inline-block; }
    .month { text-transform: uppercase; }
  }
  p { margin-bottom: 1em; }
  &, .entry-content { a { @include link-colors(inherit, $link-color-hover); }}
  a:hover { color: $link-color-hover; }
  @media only screen and (min-width: 550px) {
    article { margin-left: 5em; }
    h2 {
      margin-bottom: .3em;
      font-weight: normal;
      display: inline-block;
      position: relative; top: -1px;
      float: left;
      &:first-child { padding-top: .75em; }
    }
    time {
      text-align: right;
      left: 0em;
      top: 1.8em;
    }
    .year { display: none; }
    article {
      padding:{left: 4.5em; bottom: .7em;}
    }
  a.category {
    line-height: 1.1em;
    }
  }
}
#blog-archives {
  time {
    position: absolute;
  }
  .meta > span::after {
    content: "";
  }
}

#content > .category {
  article {
    margin-left: 0;
    padding-left: 6.8em;
  }
  .year { display: inline; }
}
