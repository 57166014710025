body > nav {
  position: relative;
  background-color: $nav-bg;
  background: $nav-bg-front, $nav-bg-back;
  border: {
    top: 1px solid $nav-border-top;
    bottom: 1px solid $nav-border-bottom; }
  padding-top: .35em;
  padding-bottom: .35em;
  form {
    background-clip: padding-box;
    margin: 0; padding: 0;
    .search {
      padding: .3em .5em 0;
      font-size: .85em;
      font-family: $sans;
      line-height: 1.1em;
      width: 95%;
      border-radius: .5em;
      background-clip: padding-box;
      box-shadow: lighten($nav-bg, 2) 0 1px;
      background-color: lighten($nav-bg, 15);
      border: 1px solid $nav-border;
      color: #888;
      &:focus {
        color: #444;
        border-color: #80b1df;
        box-shadow: #80b1df 0 0 4px, #80b1df 0 0 3px inset;
        background-color: #fff;
        outline: none;
      }
    }
  }
  fieldset[role=search]{ float: right; width: 48%; }
  fieldset.mobile-nav{ float: left; width: 48%;
    select{ width: 100%; font-size: .8em; border: 1px solid #888;}
  }
  ul { display: none; }
  @media only screen and (min-width: 550px) {
    font-size: .9em;
    ul {
      @include horizontal-list(0);
      float: left;
      display: block;
      padding-top: .15em;
    }
    ul.subscription {
      margin-left: .8em;
      float: right;
      li:last-child a { padding-right: 0; }
    }
    ul li {
      margin: 0;
    }
    a {
      @include link-colors($nav-color, $nav-color-hover, $visited: $nav-color);
      font-family: $sans;
      text-shadow: lighten($nav-bg, 12) 0 1px;
      float: left;
      text-decoration: none;
      font-size: 1.1em;
      padding: .1em 0;
      line-height: 1.5em;
    }
    li + li {
      border-left: 1px solid $nav-border-left;
      margin-left: .8em;
      a {
        padding-left: .8em;
        border-left: 1px solid $nav-border-right;
      }
    }
    form {
      float: right;
      text-align: left;
      padding-left: .8em;
      width: $sidebar-width-medium - $pad-medium*2 - $sidebar-pad-medium + 20px;
      .search {
        width: 93%;
        font-size: .95em;
        line-height: 1.2em;
      }
    }
    ul[data-subscription$=email] + form {
      width: $sidebar-width-medium - $pad-medium*2 - $sidebar-pad-medium - 58px;
      .search { width: 91%; }
    }
    fieldset.mobile-nav { display: none; }
    fieldset[role=search]{ width: 99%; }
  }
  @media only screen and (min-width: 992px) {
    form {
      width: $sidebar-width-wide - $pad-wide - $sidebar-pad-wide*2 + 10px;
    }
    ul[data-subscription$=email] + form {
      width: $sidebar-width-wide - $pad-wide - $sidebar-pad-wide*2 - 58px;
    }
  }
}
.no-placeholder {
  body > nav .search {
    background: lighten($nav-bg, 15) url('/images/search.png') .3em .25em no-repeat;
    text-indent: 1.3em;
  }
}
@mixin mask-subscription-nav($feed: 'rss.png'){
  position: relative; top: 0px;
  text-indent: -999999em;
  background-color: $nav-border-right;
  border: 0;
  padding: 0;
  &,&:after { @include mask-image($feed); }
  &:after {
    content: "";
    position: absolute; top: -1px; left: 0;
    background-color: lighten($nav-color, 25);
  }
  &:hover:after { background-color: lighten($nav-color, 20); }
}
.maskImage {
  body > nav {
    @media only screen and (min-width: 550px) {
      ul[data-subscription$=email] + form {
        width: $sidebar-width-medium - $pad-medium*2 - $sidebar-pad-medium - 32px;
      }
    }
    @media only screen and (min-width: 992px) {
      ul[data-subscription$=email] + form {
        width: $sidebar-width-wide - $pad-wide - $sidebar-pad-wide*2 - 32px;
      }
    }
  }
  ul.subscription { position: relative; top: .2em; li, a { border: 0; padding: 0; }}
  a[rel=subscribe-rss]{ @include mask-subscription-nav('rss.png'); }
  a[rel=subscribe-email]{ @include mask-subscription-nav('email.png'); }
}

