@mixin mask-image($img, $repeat: no-repeat){
  mask-image: image-url($img);
  mask-repeat: $repeat;
  width: image-width($img);
  height: image-height($img);
}

@mixin shadow-box($border: #fff .5em solid, $shadow: rgba(#000, .15) 0 1px 4px, $border-radius: .3em) {
  border-radius: $border-radius;
  box-shadow: $shadow;
  box-sizing: border-box;
  border: $border;
}

@mixin selection($bg, $color: inherit, $text-shadow: none){
  * {
    &::-moz-selection { background: $bg; color: $color; text-shadow: $text-shadow; }
    &::-webkit-selection { background: $bg; color: $color; text-shadow: $text-shadow; }
    &::selection { background: $bg; color: $color; text-shadow: $text-shadow; }
  }
}

@function text-color($color, $dark: dark, $light: light){
  $text-color: ( (red($color)*299) + (green($color)*587) + (blue($color)*114) ) / 1000;
  $text-color: if($text-color >= 150, $dark, $light);
  @return $text-color;
}

// Stolen from Compass: http://compass-style.org/reference/compass/typography/links/link_colors/
@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}

// Stolen from Compass: http://compass-style.org/reference/compass/utilities/general/clearfix/
@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Stolen from Compass: http://compass-style.org/reference/compass/typography/lists/horizontal_list/
@mixin reset-box-model {
  margin: 0;
  padding: 0;
  border: 0;
}

@mixin horizontal-list-container {
  @include reset-box-model;
  @include pie-clearfix;
}

@mixin horizontal-list-item($padding: 4px, $direction: left) {
  list-style-image: none;
  list-style-type: none;
  margin-left: 0;

  white-space: nowrap;
  float: $direction;
  @if $padding {
    padding: {
      left: $padding;
      right: $padding;
    };
    &:last-child {
      padding-#{opposite-position($direction)}: 0;
    }
  }
}

@mixin horizontal-list($padding: 4px, $direction: left) {
  @include horizontal-list-container;
  li {
    @include horizontal-list-item($padding, $direction);
  }
}

// Stolen from Compass: http://compass-style.org/reference/compass/typography/links/hover_link/
@mixin hover-link {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}
