#pinboard_linkroll {
  .pin-title, .pin-description {
    display: block;
    margin-bottom: .5em;
  }
  .pin-tag {
    @include hover-link;
    @extend .aside-alt-link;
    &:after { content: ','; }
    &:last-child:after { content: ''; }
  }
}
