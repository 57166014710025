// Here you can easily change your sites's layout.
// To give it a try, uncomment some of the lines below, make changes, rebuild your blog, and see how it works.

//$header-font-size: 1em;
//$header-padding-top: 1.5em;
//$header-padding-bottom: 1.5em;

//$max-width: 1350px;
//$indented-lists: true;

// Padding used for layout margins
//$pad-min: 18px;
//$pad-narrow: 25px;
//$pad-medium: 35px;
//$pad-wide: 55px;

// Sidebar widths used in media queries
//$sidebar-width-medium: 240px;
//$sidebar-pad-medium: 15px;
//$sidebar-pad-wide: 20px;
//$sidebar-width-wide: 300px;

.center {
  text-align: center;
}

#bionav {
  .selected {
    background-color: #cccccc;
    @include rounded(top, right);
    @include rounded(top, left);

    a {
      color: $head-hov-color;
    }
  }

  .spacer {
    display: inline;
    padding: 3px 15px;
    float: left;
  }

  a {
    text-decoration: none;
    color: $link-color;
    width: 100%;
  }

  ul {
    width: 100%;
    border-bottom: 2px solid #000000;
    margin-left: 0px;
    list-style-type: none;
    overflow: hidden;

    li {
      display: inline;
      padding: 3px 15px;
      margin-bottom: -2px;
      float: left;
    }

    li:hover {
        background-color: #cccccc;
        @include rounded(top, right);
        @include rounded(top, left);
      a {
        color: $head-hov-color;
      }
    }
  }
}

// GotV page
div#counts {
  display: flex;
  justify-content: space-between;
}

span.count {
  text-align: center;

  h1 {
    margin-bottom: 0;
  }
}

iframe.gform {
  height: -webkit-fill-available;
  margin-bottom: -8em;
}
