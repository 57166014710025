body > footer {
  @extend .sans;
  font-size: .8em;
  color: $footer-color;
  text-shadow: lighten($footer-bg, 5) 0 1px;
  background-color: $footer-bg;
  background: $footer-bg-front, $footer-bg-back;
  border-top: 1px solid $footer-border-top;
  position: relative;
  padding-top: 1em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  border-bottom-radius: .4em;
  z-index: 1;
  a {
    @include link-colors($footer-link-color, $footer-link-color-hover, $visited: $footer-link-color);
  }
  p:last-child { margin-bottom: 0; }
}
