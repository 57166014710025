.highlight, html .gist .gist-file .gist-syntax .gist-highlight {
  table td.code { width: 100%; }
  border: 1px solid $pre-border !important;
}
.highlight .line-numbers, html .gist .gist-file .gist-syntax .highlight .line_numbers {
  text-align: right;
  font-size: 13px;
  line-height: 1.45em;
  @if $solarized == light {
    background: lighten($base03, 1) $noise-bg !important;
    border-right: 1px solid darken($base02, 2) !important;
    box-shadow: lighten($base03, 2) -1px 0 inset;
    text-shadow: lighten($base02, 2) 0 -1px;
  } @else {
    background: $base02 $noise-bg !important;
    border-right: 1px solid darken($base03, 2) !important;
    box-shadow: lighten($base02, 2) -1px 0 inset;
    text-shadow: darken($base02, 10) 0 -1px;
  }
  span { color: $base01 !important; }
  padding: .8em !important;
  border-radius: 0;
}

figure.code, .gist-file, pre {
  box-shadow: rgba(#000, .06) 0 0 10px;
  .highlight pre { box-shadow: none; }
}

.gist .highlight, figure.code .highlight {
  @include selection(adjust-color($base03, $lightness: 23%, $saturation: -65%), $text-shadow: $base03 0 1px);
}
html .gist .gist-file {
  margin-bottom: 1.8em;
  position: relative;
  border: none;
  padding-top: image-height("code_bg.png") !important;
  .highlight {
    margin-bottom: 0;
  }
  .gist-syntax {
    border-bottom: 0 !important;
    background: none !important;
    .gist-highlight {
      background: $base03 !important;
    }
    .highlight pre {
      @extend .pre-code;
      padding: 0;
    }
  }
  .gist-meta {
   padding: .6em 0.8em;
   border: 1px solid lighten($base02, 2) !important;
   color: $base01;
   font-size: .7em !important;
   @if $solarized == light {
     background: lighten($base03, 2) $noise-bg;
     border: 1px solid $pre-border !important;
     border-top: 1px solid lighten($base03, 2) !important;
   } @else {
     background: $base02 $noise-bg;
   }
   @extend .sans;
   line-height: 1.5em;
    a {
      color: mix($base1, $base01) !important;
      @include hover-link;
      &:hover { color: $base1 !important; }
    }
    a[href*='#file'] {
      position: absolute; top: 0; left:0; right:-10px;
      color: #474747 !important;
      @extend .code-title;
      &:hover { color: $link-color !important; }
    }
    a[href*=raw]{
      @extend .download-source;
      top: .4em;
    }
  }
}
pre {
  background: $pre-bg $noise-bg;
  border-radius: .4em;
  @extend .mono;
  border: 1px solid $pre-border;
  line-height: 1.45em;
  font-size: 13px;
  margin-bottom: 2.1em;
  padding: .8em 1em;
  color: $pre-color;
  overflow: auto;
}
h3.filename {
  @extend .code-title;
  + pre { border-top-radius: 0px; }
}

p, li {
  code {
    @extend .mono;
    display: inline-block;
    white-space: no-wrap;
    background: #fff;
    font-size: .8em;
    line-height: 1.5em;
    color: #555;
    border: 1px solid #ddd;
    border-radius: .4em;
    padding: 0 .3em;
    margin: -1px 0;
  }
  pre code { font-size: 1em !important; background: none; border: none; }
}

.pre-code {
  font-family: $mono !important;
  overflow: scroll;
  overflow-y: hidden;
  display: block;
  padding: .8em;
  overflow-x: auto;
  line-height: 1.45em;
  background: $base03 $noise-bg !important;
  color: $base1 !important;
  span { color: $base1 !important; }
  span { font-style: normal !important; font-weight: normal !important; }

  .c      { color: $base01 !important; font-style: italic !important; }                     /* Comment */
  .cm     { color: $base01 !important; font-style: italic !important; }                     /* Comment.Multiline */
  .cp     { color: $base01 !important; font-style: italic !important;  }                     /* Comment.Preproc */
  .c1     { color: $base01 !important; font-style: italic !important; }                     /* Comment.Single */
  .cs     { color: $base01 !important; font-weight: bold !important; font-style: italic !important; }   /* Comment.Special */
  .err    { color: $solar-red !important; background: none !important; }                                            /* Error */
  .k      { color: $solar-orange !important; }                       /* Keyword */
  .o      { color: $base1 !important; font-weight: bold !important; }                       /* Operator */
  .p      { color: $base1 !important; }                                             /* Operator */
  .ow     { color: $solar-cyan !important; font-weight: bold !important; }                       /* Operator.Word */
  .gd     { color: $base1 !important; background-color: mix($solar-red, $base03, 25%) !important; display: inline-block; }               /* Generic.Deleted */
  .gd .x  { color: $base1 !important; background-color: mix($solar-red, $base03, 35%) !important; display: inline-block; }               /* Generic.Deleted.Specific */
  .ge     { color: $base1 !important; font-style: italic !important; }                      /* Generic.Emph */
  //.gr     { color: #aa0000 }                                          /* Generic.Error */
  .gh     { color: $base01 !important; }                                          /* Generic.Heading */
  .gi     { color: $base1 !important; background-color: mix($solar-green, $base03, 20%) !important; display: inline-block; }               /* Generic.Inserted */
  .gi .x  { color: $base1 !important; background-color: mix($solar-green, $base03, 40%) !important; display: inline-block; }               /* Generic.Inserted.Specific */
  //.go     { color: #888888 }                                          /* Generic.Output */
  //.gp     { color: #555555 }                                          /* Generic.Prompt */
  .gs     { color: $base1 !important; font-weight: bold !important; }                                       /* Generic.Strong */
  .gu     { color: $solar-violet !important; }                                          /* Generic.Subheading */
  //.gt     { color: #aa0000 }                                          /* Generic.Traceback */
  .kc     { color: $solar-green !important; font-weight: bold !important; }                       /* Keyword.Constant */
  .kd     { color: $solar-blue !important; }                       /* Keyword.Declaration */
  .kp     { color: $solar-orange !important; font-weight: bold !important; }                       /* Keyword.Pseudo */
  .kr     { color: $solar-magenta !important; font-weight: bold !important; }                       /* Keyword.Reserved */
  .kt     { color: $solar-cyan !important; }                       /* Keyword.Type */
  .n      { color: $solar-blue !important; }
  .na     { color: $solar-blue !important; }                                          /* Name.Attribute */
  .nb     { color: $solar-green !important; }                                          /* Name.Builtin */
  .nc     { color: $solar-magenta !important;}                                                   /* Name.Class */
  .no     { color: $solar-yellow !important; }                                          /* Name.Constant */
  //.ni     { color: #800080 }                                          /* Name.Entity */
  .nl     { color: $solar-green !important; }
  .ne     { color: $solar-blue !important; font-weight: bold !important; }                       /* Name.Exception */
  .nf     { color: $solar-blue !important; font-weight: bold !important; }                       /* Name.Function */
  .nn     { color: $solar-yellow !important; }                                          /* Name.Namespace */
  .nt     { color: $solar-blue !important; font-weight: bold !important; }                                          /* Name.Tag */
  .nx     { color: $solar-yellow !important; }
  //.bp     { color: #999999 }                                          /* Name.Builtin.Pseudo */
  //.vc     { color: #008080 }                                          /* Name.Variable.Class */
  .vg     { color: $solar-blue !important; }                                          /* Name.Variable.Global */
  .vi     { color: $solar-blue !important; }                                          /* Name.Variable.Instance */
  .nv     { color: $solar-blue !important; }                                          /* Name.Variable */
  //.w      { color: #bbbbbb }                                          /* Text.Whitespace */
  .mf     { color: $solar-cyan !important; }                                          /* Literal.Number.Float */
  .m      { color: $solar-cyan !important; }                                          /* Literal.Number */
  .mh     { color: $solar-cyan !important; }                                          /* Literal.Number.Hex */
  .mi     { color: $solar-cyan !important; }                                          /* Literal.Number.Integer */
  //.mo     { color: #009999 }                                          /* Literal.Number.Oct */
  .s      { color: $solar-cyan !important; }                                             /* Literal.String */
  //.sb     { color: #d14 }                                             /* Literal.String.Backtick */
  //.sc     { color: #d14 }                                             /* Literal.String.Char */
  .sd     { color: $solar-cyan !important; }                                             /* Literal.String.Doc */
  .s2     { color: $solar-cyan !important; }                                             /* Literal.String.Double */
  .se     { color: $solar-red !important; }                                             /* Literal.String.Escape */
  //.sh     { color: #d14 }                                             /* Literal.String.Heredoc */
  .si     { color: $solar-blue !important; }                                             /* Literal.String.Interpol */
  //.sx     { color: #d14 }                                             /* Literal.String.Other */
  .sr     { color: $solar-cyan !important; }                                          /* Literal.String.Regex */
  .s1     { color: $solar-cyan !important; }                                             /* Literal.String.Single */
  //.ss     { color: #990073 }                                          /* Literal.String.Symbol */
  //.il     { color: #009999 }                                          /* Literal.Number.Integer.Long */
  div { .gd, .gd .x, .gi, .gi .x { display: inline-block; width: 100%; }}
}

.highlight, .gist-highlight {
  pre { background: none; border-radius: 0px; border: none; padding: 0; margin-bottom: 0; }
  margin-bottom: 1.8em;
  background: $base03;
  overflow-y: hidden;
  overflow-x: auto;
}

$solar-scroll-bg: rgba(#fff, .15);
$solar-scroll-thumb: rgba(#fff, .2);
@if $solarized == light {
  $solar-scroll-bg: rgba(#000, .15);
  $solar-scroll-thumb: rgba(#000, .15);
}

pre, .highlight, .gist-highlight {
  &::-webkit-scrollbar {  height: .5em; background: $solar-scroll-bg; }
  &::-webkit-scrollbar-thumb:horizontal { background: $solar-scroll-thumb;  -webkit-border-radius: 4px; border-radius: 4px }
}

.highlight code { 
  @extend .pre-code; background: #000;
}
figure.code {
  background: none;
  padding: 0;
  border: 0;
  margin-bottom: 1.5em;
  pre { margin-bottom: 0; }
  figcaption {
    position: relative;
    @extend .code-title;
    a { @extend .download-source; }
  }
  .highlight {
    margin-bottom: 0;
  }
}

.code-title {
  text-align: center;
  font-size: 13px;
  line-height: 2em;
  text-shadow: #cbcccc 0 1px 0;
  color: #474747;
  font-weight: normal;
  margin-bottom: 0;
  border-top-radius: 5px;
  font-family: "Helvetica Neue", Arial, "Lucida Grande", "Lucida Sans Unicode", Lucida, sans-serif;
  background: #aaaaaa image-url("code_bg.png") top repeat-x;
  border: 1px solid #565656;
  border-top-color: #cbcbcb;
  border-left-color: #a5a5a5;
  border-right-color: #a5a5a5;
  border-bottom: 0;
}

.download-source {
  position: absolute; right: .8em;
  @include hover-link;
  color: #666 !important;
  z-index: 1;
  font-size: 13px;
  text-shadow: #cbcccc 0 1px 0;
  padding-left: 3em;
}
