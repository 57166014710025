.googleplus {
  h1 {
       -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
         -o-box-shadow: none !important;
            box-shadow: none !important;
    border-bottom: 0px none !important;
  }
  a {
    text-decoration: none;
    white-space: normal !important;
    line-height: 32px;

    img {
      float: left;
      margin-right: 0.5em;
      border: 0 none;
    }
  }
}

.googleplus-hidden {
  position: absolute;
  top: -1000em;
  left: -1000em;
}
