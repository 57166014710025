body > header {
  background: $header-bg;
  h1 {
    display: inline-block;
    margin: 0;
    a, a:visited, a:hover {
      color: $title_color;
      text-decoration: none;
    }
  }
  h2 {
    margin: .2em 0 0;
    @extend .sans;
    font-size: 1em;
    color: $subtitle-color;
    font-weight: normal;
  }
}
